<template>
  <div class="w-100">
    <div class="d-flex align-items-center py-2 questions-type mb-3">
      <span class="mr-3 d-md-block d-none">Você pode filtrar por: </span>
      <b-button
        :variant="
          selectedQuestionType.name === questionType.name ? 'primary' : 'light'
        "
        v-for="(questionType, index) in questionsTypes"
        :key="index"
        @click="handleSelectQuestionType(questionType)"
        class="text-center d-md-flex align-items-center px-2 questions-type"
      >
        <strong>{{ questionType.name }}</strong>
      </b-button>
    </div>

    <b-badge :variant="isAnswered ? 'success' : 'danger'" class="d-table">
      {{
        isAnswered
          ? "Todas as pergundas foram respondidas"
          : "Você precisa responder todas as perguntas"
      }}
    </b-badge>

    <div class="questions w-100">
      <div class="questions-list mt-3 w-100">
        <skeleton
          height="58px"
          grid="1"
          gap="10"
          :rows="5"
          v-if="questions && questions.length === 0 && !emptyQuestions"
        />
        <empty-list v-if="emptyQuestions" text="Não há perguntas cadastradas" />

        <b-card
          v-for="(question, index) in questions"
          :key="index"
          class="w-100 py-2 px-4 mb-2"
          no-body
        >
          <div class="mb-2">
            <span class="text-primary">
              {{ questionsTypes.find(({ id }) => id === question.type).name }}
            </span>
          </div>
          <section
            class="
              d-block d-md-flex
              align-items-center
              justify-content-between
              flex-md-row
            "
          >
            <section class="w-100 mr-4 mb-3 mb-md-0">
              <strong class="d-block">
                {{ question.quest }}
              </strong>
              <small
                class="mt-1"
                label="name"
                placeholder="Observação da pergunta"
              >
                {{ question.observation }}
              </small>
            </section>
            <section class="d-flex align-items-center gap-1">
              <section
                v-if="question.files && question.files.length > 0"
                class="has-inputed"
              >
                <b-button
                  variant="link"
                  size="sm"
                  class="mb-0"
                  @click="handleOpenEvidences(question)"
                >
                  <span class="d-flex">
                    <b-icon icon="paperclip" class="mr-1" />
                    <span
                      class="text-nowrap"
                      v-if="question.files && question.files.length > 0"
                    >
                      {{ question.files.length }}
                      {{
                        question.files.length > 1 ? "evidências" : "evidência"
                      }}
                    </span>
                  </span>
                </b-button>
              </section>
              <section
                class="d-flex align-items-center"
                v-if="is_provider && !isAnswered"
              >
                <b-button
                  size="sm"
                  :variant="question.in_compliance ? 'success' : 'light'"
                  class="mr-2 mb-0"
                  @click="handleSetAnswer(true, question, index)"
                  :disabled="
                    question && question.files && question.files.length > 0
                  "
                >
                  Sim
                </b-button>
                <b-button
                  class="mb-0"
                  size="sm"
                  :variant="
                    question.in_compliance === false ? 'danger' : 'light'
                  "
                  @click="handleSetAnswer(false, question, index)"
                >
                  Não
                </b-button>
              </section>
              <section v-else class="d-flex gap-2">
                <span>Resposta:</span>
                <strong
                  :class="
                    question.in_compliance ? 'text-success' : 'text-danger'
                  "
                >
                  {{ question.in_compliance ? "Sim" : "Não" }}
                </strong>
              </section>
            </section>
          </section>
        </b-card>
      </div>
    </div>
    <b-modal
      id="add-evidence"
      size="md"
      hide-footer
      centered
      title="É necessário adicionar uma evidência à resposta"
    >
      <file-upload @upload="handleUploadEvidence" />
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleDeleteProfile()">
          Salvar
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="files-uploaded"
      centered
      size="md"
      title="Evidências anexadas"
      hide-footer
    >
      <div class="mb-3">
        <div class="d-flex justify-content-end">
          <b-button
            class="mb-4"
            size="sm"
            @click="handleAddEvidence(selectedQuestion)"
          >
            Adicionar evidência
          </b-button>
        </div>

        <b-card
          v-for="(file, index) in uploadedFiles"
          :key="index"
          no-body
          class="
            p-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-2
          "
          body-class=""
        >
          <a
            class="text-left d-flex align-items-center line-height-1"
            :href="file.file"
            target="_blank"
            download
          >
            <div class="d-flex">
              <b-icon icon="paperclip" class="mr-2 m-0" />
              <span> {{ file.name }}</span>
            </div>
          </a>
          <a @click="handleDeleteFile(file)" size="sm" class="mb-0 icon-action">
            <b-icon icon="trash" class="pointer" />
          </a>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FileUpload from "@/components/FileUpload";
import { api } from "@/services/api";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      service_id: "",
      provider_id: "",
      selectedQuestion: null,
      emptyQuestions: false,
      is_preview_admin_snapshot: false,
      loading: false,
      isAnswered: false,
      selectedQuestionType: "",
      questionsTypes: [],
      questions: [],
      sourceOptions: [],
      uploadedFiles: [],
    };
  },

  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user", "is_provider"]),
  },
  methods: {
    ...mapActions([
      "get_all_questions_type",
      "get_all_questions",
      "get_snapshot",
    ]),

    handleOpenEvidences(question) {
      this.selectedQuestion = question;
      this.$bvModal.show("files-uploaded");
      this.uploadedFiles = question.files;
    },

    saveAnswerQuestion(question) {
      const data = {
        provider: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
        question: question.id,
        service: this.service_id,
        files: question.files?.flatMap(({ id }) => [id]) || [],
        in_compliance: question.in_compliance,
      };
      if (question.response_id) {
        api
          .patch(`/question-response/${question.response_id}/`, {
            ...data,
          })
          .then(() => {
            this.handleGetQuestions();
          });
      } else {
        api.post("/question-response/", { ...data }).then(() => {
          this.handleGetQuestions();
        });
      }
      this.get_snapshot({
        type: "questionaire",
        service_id: this.service_id,
        provider_id: this.provider_id,
      });
    },

    handleSelectQuestionType(qst) {
      if (this.selectedQuestionType === qst) {
        this.selectedQuestionType = { id: null };
      } else {
        this.selectedQuestionType = qst;
      }
      this.handleGetQuestions();
    },

    handleSetAnswer(answer, _, index) {
      if (this.is_preview_admin_snapshot) return;

      this.questions[index].in_compliance = answer;

      const question = this.questions[index];

      if (question.in_compliance === null) {
        return;
      }

      if (question.in_compliance === true) {
        return this.handleAddEvidence(question);
      }

      if (question.in_compliance && question.files?.length > 0) {
        return this.saveAnswerQuestion(question);
      }
      this.questions[index].files = null;
      this.saveAnswerQuestion(question);
    },

    handleUploadEvidence(files) {
      const index = this.questions.indexOf(
        this.questions.find((qst) => qst.id === this.selectedQuestion.id)
      );
      let arrFiles = this.questions[index].files || [];
      arrFiles = [...arrFiles, ...files];
      this.questions[index].files = arrFiles;
      this.$bvModal.hide("add-evidence");
      this.$bvModal.hide("files-uploaded");
      this.saveAnswerQuestion(this.questions[index]);
      this.$forceUpdate();
    },

    handleAddEvidence(question) {
      this.selectedQuestion = question;
      this.$bvModal.show("add-evidence");
    },

    handleDeleteFile(file, index) {
      const indexQuestion = this.questions.indexOf(
        this.questions.find((qst) => qst.id === this.selectedQuestion.id)
      );

      api.delete(`/upload/${file.id}`).then(() => {
        this.questions[indexQuestion].files.splice(index, 1);

        this.handleSetAnswer(false, this.selectedQuestion, indexQuestion);

        this.$forceUpdate();
        if (this.questions[indexQuestion]?.files?.length === 0) {
          this.$bvModal.hide("files-uploaded");
        }
      });
    },

    handleGetQuestions() {
      this.loading = true;
      const type = this.selectedQuestionType.id;

      let providerId = this.is_preview
        ? this.preview_personify?.id
        : this.user?.enterprise?.id;

      if (this.provider_id) {
        providerId = this.provider_id;
      }

      api
        .get(`/question-response/${providerId}/${this.service_id}/`, { type })
        .then(({ data }) => {
          this.loading = false;
          this.emptyQuestions = data.results?.length === 0;
          this.questions = data.results;
        });
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.is_preview_admin_snapshot = !!this.$route.params.ranking_id;

    this.get_all_questions_type().then(({ data }) => {
      this.questionsTypes = data.results;
      this.handleGetQuestions();
    });
    api
      .get(`/question-full/${this.user.enterprise_id}/${this.service_id}/`)
      .then(({ data }) => {
        this.isAnswered = data.status;
      });
  },
  created() {
    this.$root.$on("bv::modal::hidden", (bevent, id) => {
      if (id === "add-evidence") {
        this.handleGetQuestions();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.questions-type {
  strong {
    text-align: left;
    line-height: 1;
  }
}
.attachment {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.has-inputed {
  min-width: 165px;
  text-align: right;
}
</style>
